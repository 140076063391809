.navbar {
  background-color: var(--light);

  .navbar-brand a {
    color: var(--gray);
  }

  .nav-link {
    text-transform: uppercase;
  }
}
