#homePage{
  .page-title {
    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    text-transform: uppercase;
    color: var(--primary);

    .spinner-grow {
      color: var(--secondary);
    }
  }
}
