@import '../../styles/mixins';

.gallery-item {
  padding: 0 8px 14px 12px;

  .card {
    @include boxShadow();
    border-radius: 0;

    .card-title {
      color: var(--primary);
    }

    .tags {
      .badge:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
}
