@import '../../styles/mixins';

#contactPage {
  #contactForm {
    max-width: 600px;
    margin: 0 auto;
  }

  button + button {
    margin-left: 10px;
  }
}
