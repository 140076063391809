@import '../../styles/mixins';

.single-image-item {
  margin: 0 auto 25px auto;
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 60px rgba(0, 0, 0, .07);

  figure {
    margin: 1rem auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .spinner-conrainer {
      margin: 0 auto;
    }
  }
}
