@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

#linksPage {
  padding: 0 20px 40px 20px;

  #categories {
    padding-bottom: 20px;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;

    .btn {
      margin-bottom: 7px;
      margin-right: 3px;
    }
  }

  .badge-light {
    background-color: white;
    color: var(--secondary);
  }

  .cat + .cat {
    padding-left: 7px;
  }
}

.link-item {
  padding: 10px 20px;
  &:not(:last-child) {
    border-bottom: 1px solid darken(#ebebeb, 7);
  }

  .item-description {
    font-size: 14px;
    color: var(--gray);
  }

  .cat {
    font-size: 14px;
  }

  .cat-light {
    color: var(--secondary);
  }

}
