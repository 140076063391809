@import url('https://fonts.googleapis.com/css?family=Open+Sans|Space+Mono');

code {
  font-family: 'Space Mono', Menlo, Monaco, Consolas, 'Courier New', monospace;
}

$font-family-sans-serif: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
// $primary: #260bb5;

$theme-colors: (
  // primary: #403a37,
  primary: #607D8B,
  secondary: #ef7a61,
  dark: #302520,
  light: #ebebeb,
);

// color: #f19978;
// color: #d4614d;
// @import "../node_modules/bootstrap/scss/variables";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins";
// picking
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/utilities";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light);
}

.page-title {
  font-size: 2rem;
}

.page-container {
  margin-top: 75px;
}
