@mixin boxShadow() {
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}

@mixin boxShadow2() {
  &:before, &:after {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 15px;
    width: 50%;
    top: 80%;
    max-width: 300px;
    background: #777;
    box-shadow: 0 15px 10px #777;
  }
  &:before {
    left: 15px;
    right: auto;
    transform: rotate(-3deg);
  }
  &:after {
    transform: rotate(3deg);
    right: 15px;
    left: auto;
  }
}
